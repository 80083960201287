<template>
    <div style="width: 100%;height: 40px;color: #000000;display: flex;align-items: center;justify-content: center;text-align: center">
        <div style="width: 120px">
            <i class="fa fa-user-circle" style="font-weight: bold;font-size: 14px">
                {{$store.state.agent.agentInfo.nickname}}</i>
        </div>
        <div style="width: 100px">
            <i class="fa fa-won" style="font-weight: bold;font-size: 14px">
                {{$store.state.agent.agentInfo.cash|comma}}</i>
        </div>
        <div style="width: 180px">
            <i class="fa fa-percent" style="font-weight: bold;font-size: 14px">Deposit/withdrawal profit:
                {{$store.state.agent.agentInfo.rechargeExchangeProfit}}%</i>
        </div>
        <div style="width: 160px">
            <i class="fa fa-percent" style="font-weight: bold;font-size: 14px">Sports Rolling:
                {{$store.state.agent.agentInfo.sportsBetRollingProfit}}%</i>
        </div>
<!--        <div style="width: 160px">-->
<!--            <i class="fa fa-percent" style="font-weight: bold;font-size: 14px">미니게임롤링:-->
<!--                {{$store.state.agent.agentInfo.leisureBetRollingProfit}}%</i>-->
<!--        </div>-->
        <div style="width: 160px">
            <i class="fa fa-percent" style="font-weight: bold;font-size: 14px">Casino Rolling:
                {{$store.state.agent.agentInfo.casinoBetRollingProfit}}%</i>
        </div>
        <div style="width: 160px">
            <i class="fa fa-percent" style="font-weight: bold;font-size: 14px">Slot Rolling:
                {{$store.state.agent.agentInfo.slotBetRollingProfit}}%</i>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AgentInfoComp"
    }
</script>

<style scoped>

</style>